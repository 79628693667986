<template>
	<div class="call-filter">
		<button
			id="provider-filter-toggle"
			class="call-filter-toggle btn btn-outline-dark"
			aria-haspopup="listbox"
			aria-label="Provider filter"
			@click.prevent="onToggleClick"
		>
			<div class="btn-ripple"></div>
			<span v-if="hasFilter" class="btn-label call-filter-toggle-text">
				Providers ({{ selected }})
			</span>
			<span v-else class="btn-label call-filter-toggle-text">
				Providers
			</span>

			<font-awesome-icon
				class="btn-icon"
				:icon="['fal', show ? 'chevron-up' : 'chevron-down']"
			/>
		</button>
		<transition name="fade">
			<div
				v-if="show"
				class="call-filter-dropdown providers"
				:class="{ show }"
			>
				<div
					id="call-filter-list"
					tabindex="-1"
					role="listbox"
					aria-label="Choose a call filter"
					aria-multiselectable="true"
					class="call-filter-dropdown-list"
					@click.stop
				>
					<div class="call-filter-toggle-all">
						<button
							v-if="enableSelect"
							type="button"
							class="btn btn-dark"
							@click="selectAll"
						>
							<div class="btn-ripple"></div>
							<span class="btn-label">Select All</span>
						</button>
						<button
							v-else
							type="button"
							class="btn btn-dark"
							@click="deselectAll"
						>
							<div class="btn-ripple"></div>
							<span class="btn-label">Deselect All</span>
						</button>
					</div>

					<div class="call-filter-header">
						Providers
					</div>

					<div
						v-for="provider in providers"
						:id="`provider-item-${provider.id}`"
						:key="`${provider.id}`"
						class="call-filter-item"
						role="option"
					>
						<input
							:id="`provider-filter-${provider.id}`"
							v-model="filters"
							type="checkbox"
							:value="provider.id"
							:checked="isChecked(provider.id)"
							class="call-filter-option-checkbox"
						/>

						<label
							class="call-filter-option-label"
							:for="`provider-filter-${provider.id}`"
						>
							{{ provider | toProviderShortName }}
						</label>
					</div>
					<div class="call-filter-submit">
						<button
							type="button"
							class="btn btn-outline-warning w-100"
							:disabled="applyingFilter"
							@click.prevent.stop="onApplyFiltersClick"
						>
							<div class="btn-ripple"></div>
							<span class="btn-label">Apply Filters</span>
							<font-awesome-icon
								v-if="applyingFilter"
								spin
								fixed-width
								size="1x"
								margin="0 0 0 10px"
								:icon="['far', 'spinner-third']"
							/>
						</button>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

/**
 * The escape numeric key code.
 *
 * @type {Number}
 */
const ESCAPE_KEY_CODE = 27

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if any filter is active.
		 *
		 * @return {Boolean}
		 */
		hasFilter() {
			if (this.isPagesFilter) {
				return this.hasPagesProviderFilter
			}

			return this.hasCallsProviderFilter
		},

		/**
		 * Number of selected items on dropdown.
		 *
		 * @return {Number}
		 */
		selected() {
			return this.getCurrentModuleFilters('length')
		},

		/**
		 * For checkboxes of selected items on dropdown.
		 *
		 * @return {Array}
		 */
		selectedProviders() {
			return this.getCurrentModuleFilters('providers')
		},

		/**
		 * Total number of options.
		 *
		 * @return {Number}
		 */
		totalOptions() {
			return this.providers.length
		},

		/**
		 * Constructs and returns the payload for fetching call data.
		 *
		 * @returns {Object}
		 */
		getCallsFetchingPayload() {
			const payload = {
				fromFilters: true,
				callId: this.$route.params.sid,
			}
			return payload
		},

		...mapGetters({
			partner: 'partner',
			hasCallsProviderFilter: 'calls/hasProviderFilter',
			hasPagesProviderFilter: 'pages/hasProviderFilter',
			providers: 'providers/internal',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		isChecked(providerId) {
			return this.filters.includes(providerId)
		},

		/**
		 * Helper method to get the current module's filters.
		 *
		 * @param {String} type - The type of filter data to retrieve ('length' or 'providers').
		 * @return {Number|Array} - Returns the length of providers or the providers array based on the type.
		 */
		getCurrentModuleFilters(type) {
			const currentPath = this.$route.path

			if (currentPath.includes('/paging')) {
				return type === 'length'
					? this.$store.state.pages.filters.providers.length
					: this.$store.state.pages.filters.providers
			}

			if (currentPath.includes('/calls')) {
				return type === 'length'
					? this.$store.state.calls.filters.providers.length
					: this.$store.state.calls.filters.providers
			}

			return type === 'length' ? 0 : []
		},

		/**
		 * Add the escape keydown event listeners.
		 *
		 * @return {void}
		 */
		addEventListeners() {
			document.addEventListener('click', this.onOutsideClick)
			document.addEventListener('keydown', this.onEscapeKeyDown)
		},

		/**
		 * Apply the filters and load the calls.
		 *
		 * @return {void}
		 */
		async applyCallFilters() {
			this.setCallsFilters(this.getFiltersAsObject())

			this.show = false

			this.removeEventListeners()

			await this.fetchCalls(this.getCallsFetchingPayload)
		},

		/**
		 * Apply the filters and load the pages.
		 *
		 * @return {void}
		 */
		async applyPagesFilters() {
			try {
				this.applyingFilter = true

				this.setPagesFilters(this.getFiltersAsObject())

				this.removeEventListeners()

				await this.fetchPages(this.getCallsFetchingPayload)
			} finally {
				this.applyingFilter = false
				this.show = false
			}
		},

		/**
		 * Remove all selected filters and types.
		 *
		 * @return {void}
		 */
		deselectAll() {
			this.enableSelect = true
			this.filters = []
		},

		/**
		 * Get the all of the filters as a store payload object.
		 *
		 * @return {Object}
		 */
		getFiltersAsObject() {
			return {
				providers: this.filters,
			}
		},

		/**
		 * Determine if the given keycode is the escape key.
		 *
		 * @param {Number} keyCode
		 * @return {Boolean}
		 */
		isEscapeKeyCode(keyCode) {
			return keyCode === ESCAPE_KEY_CODE
		},

		/**
		 * Handle the apply filters click event.
		 *
		 * @return {void}
		 */
		async onApplyFiltersClick() {
			if (this.isPagesFilter) {
				return this.applyPagesFilters()
			}

			this.applyCallFilters()
		},

		/**
		 * Handle the escape keydown event.
		 *
		 * @returns {void}
		 */
		onEscapeKeyDown(event) {
			if (this.show && this.isEscapeKeyCode(event.keyCode)) {
				this.show = false

				this.removeEventListeners()
			}
		},

		/**
		 * Handle a global click event outside of the dropdown toggler.
		 *
		 * @return {void}
		 */
		onOutsideClick() {
			if (this.show) {
				this.show = false

				setTimeout(() => this.removeEventListeners(), 0)
			}
		},

		/**
		 * Handle the dropdown click event.
		 *
		 * @return {void}
		 */
		async onToggleClick() {
			this.show = !this.show

			setTimeout(() => {
				return this.show
					? this.addEventListeners()
					: this.removeEventListeners()
			}, 0)
		},

		/**
		 * Mark all types and filters as selected.
		 *
		 * @return {void}
		 */
		selectAll() {
			this.enableSelect = false
			this.filters = this.providers.map(provider => provider.id)
		},

		/**
		 * Remove the global event listeners.
		 *
		 * @return {void}
		 */
		removeEventListeners() {
			document.removeEventListener('keydown', this.onEscapeKeyDown)
			document.removeEventListener('click', this.onOutsideClick)
		},

		...mapActions('calls', {
			fetchCalls: 'get',
			setCallsFilters: 'setFilters',
		}),

		...mapActions('pages', {
			fetchPages: 'get',
			setPagesFilters: 'setFilters',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ProviderFilterDropdown',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * Determine if should filter calls or pages.
		 */
		isPagesFilter: {
			type: Boolean,
			default: false,
		},
	},

	watch: {
		'partner.id'(newPartnerId, oldPartnerId) {
			if (newPartnerId !== oldPartnerId) {
				this.filters = []
				this.$nextTick(() => {
					this.getCurrentModuleFilters('length')
				})
			}
		},
	},

	mounted() {
		this.filters = this.selectedProviders
	},

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.removeEventListeners()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			filters: [],
			show: false,
			enableSelect: true,
			applyingFilter: false,
		}
	},
}
</script>
